<template>
  <main class="cart">
    <div class="container pa-0">
      <v-container
        fluid
        class="cart__table"
        :class="[$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'pa-3']"
      >
        <v-container
          v-if="$vuetify.breakpoint.mdAndUp && cartItems.length"
          fluid
          class="pa-0 d-flex flex-column"
        >
          <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
            class="cart__table-header ma-0"
          >
            <v-col cols="5" class="py-0">Description</v-col>
            <v-col cols="1" class="py-0">Price</v-col>
            <v-col cols="1" class="py-0">Discount</v-col>
            <v-col style="text-align: center" class="py-0">Quantity</v-col>
            <v-col cols="2" class="py-0">Item Total</v-col>
          </v-row>
          <v-row
            class="cart__table-item ma-0"
            v-for="item in cartItems"
            :key="item.cartId"
          >
            <v-col cols="5" class="cart__table-item__desc py-0">
              <div @click="selectProduct(item)" class="cursor-pointer">
                <img
                  v-if="item.variation.id"
                  :src="baseUrl + item.product.photo"
                  alt="shoes img"
                  width="87"
                  height="77"
                />
                <img
                  v-else
                  :src="baseUrl + item.product?.photo"
                  alt="shoes img"
                  width="87"
                  height="77"
                />
              </div>
              <div
                class="d-flex flex-column cursor-pointer"
                @click="selectProduct(item)"
              >
                <h4>{{ item.product.name.ru }}</h4>
                <span v-if="item.variation.title.ru" class="mb-2">
                  Цвет: {{ item.variation.title.ru }}
                </span>
                <div v-if="item.size.size" class="d-flex flex-column">
                  <span>
                    Размер обуви:
                    {{ `${item.size.size} * ${item.size.count}` }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="1" class="cart__table-item__price py-0">
              <span>
                {{ item.size.price.view }}
                {{ item.size.currency?.currency?.currency }}
              </span
              >
            </v-col>
            <v-col
              v-if="item?.size?.price?.discount?.discounts[0]?.value"
              cols="1"
              class="cart__table-item__price py-0"
            >
              <span
                >{{
                  item?.size?.price?.discount?.discounts[0]?.value &&
                  item?.size?.price?.discount?.discounts[0]?.value
                }}%</span
              >
            </v-col>
            <v-col class="cart__table-item__count py-0">
              <img
                src="/img/icons/minus-icon.svg"
                alt="minus img"
                width="33"
                height="33"
                @click="decrementSizeCount(item.cartId)"
              />
              <!-- @click="decreaseCartItemCount(item.cartId)" -->
              <div>
                {{ item.size.count }}
              </div>
              <img
                @click="incrementSizeCount(item.cartId)"
                src="/img/icons/plus-green.svg"
                alt="plus img"
                width="33"
                height="33"
              />
              <!-- @click="increaseCartItemCount(item.cartId)" -->
            </v-col>
            <v-col cols="2" class="cart__table-item__price py-0 pl-0 pr-6">
              <span v-if="item?.size?.price?.discount?.view">
                {{
                  (
                    (item?.size?.price?.view -
                      item?.size?.price?.discount?.view) *
                    item?.size?.count
                  ).toFixed(2)
                }}
                {{ item.size.currency?.currency?.currency }}
              </span>
              <span v-else>
                {{ item?.size?.price?.view * item?.size?.count }}
                {{ item.size.currency?.currency?.currency }}

              </span>
              <img
                src="/img/icons/x.svg"
                @click="removeCartItem(item.cartId)"
                alt="x"
                width="10"
                height="10"
              />
            </v-col>
          </v-row>
        </v-container>
        <div
          class="cart__table-item__mob"
          v-for="(item, index) in cartItems"
          :key="item.cartId"
          :class="{ 'mb-3': index !== cartItems.length - 1 }"
        >
          <div class="cart__table-item__mob-left">
            <img
              :src="baseUrl + item.product.photo"
              alt="shoes img"
              width="157"
              height="149"
            />
          </div>
          <div class="cart__table-item__mob-right">
            <div class="cart__table-item__mob-right__desc">
              <h4>{{ item.product.name.ru }}</h4>
              <img
                src="/img/icons/x.svg"
                @click="removeCartItem(item.cartId)"
                alt="x"
                width="10"
                height="10"
              />
            </div>
            <div
              class="cart__table-item__mob-right__size mb-1 flex-column align-start"
            >
              <span v-if="item.colors" class="mb-1">
                Цвет: {{ item.variation.title.ru }}
              </span>
              <div class="d-flex flex-column">
                <span>
                  Размер обуви:
                  {{ `${item.size.size} * ${item.size.count}` }}
                </span>
              </div>
            </div>
            <div class="cart__table-item__mob-right__quantity mb-1">
              <p>Quantity</p>
              <div class="cart__table-item__mob-right__quantity-count">
                <img
                  src="/img/icons/minus-icon.svg"
                  alt="minus img"
                  width="23"
                  height="23"
                />
                <!-- @click="decreaseCartItemCount(item.cartId)" -->
                <div>{{ item.size.count }}</div>
                <img
                  src="/img/icons/plus-green.svg"
                  alt="plus img"
                  width="23"
                  height="23"
                />
                <!-- @click="increaseCartItemCount(item.id)" -->
              </div>
            </div>
            <div class="cart__table-item__mob-right__price">
              <p>Price</p>
              <span>
                {{ item?.size?.price?.discount?.view }}
                {{ item.size.currency?.currency?.currency }}
              </span
              >
            </div>
          </div>
        </div>
        <v-row
          v-if="cartItems.length"
          class="cart__table-price my-0 d-flex justify-content-between"
        >
          <!-- <v-col cols="8" md="4" class="px-3">
            <div class="cart__table-price__item">
              <p class="cart__table-price__item-title">Delivery</p>
              <p class="cart__table-price__item-price">
                <span>{{ deliveryPrice.toFixed(2) }}</span>
                <span>0</span>
              </p>
            </div>
          </v-col> -->
          <v-col cols="8" md="4" class="px-3">
            <div class="cart__table-price__item">
              <p class="cart__table-price__item-title">Subtotal</p>
              <p class="cart__table-price__item-price">
                <span>
                  {{ subtotal }}
                  {{ cartItems[0]?.size.currency?.currency?.currency}}
                </span>
              </p>
            </div>
          </v-col>
          <v-col cols="8" md="4" class="px-3">
            <div class="cart__table-price__item">
              <p class="cart__table-price__item-title">Total</p>
              <p class="cart__table-price__item-price">
                <span>
                  {{ total.toFixed(2) }}
                  {{ cartItems[0]?.size.currency?.currency?.currency}}
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="cart__table-footer pt-3"
          :class="{ 'justify-center': !cartItems.length }"
          no-gutters
        >
          <button
            class="cart__table-footer__btn"
            @click="
              $router.push({
                name: 'Categories',
                query: { section: 'felt shoes' },
              })
            "
            >continue shopping</button
          >
          <button
            v-if="cartItems.length"
            class="cart__table-footer__btn"
            @click="
              $router.push({
                name: 'Registration',
                params: { order: cartItems },
              })
            "
            >continue order</button
          >
        </v-row>
      </v-container>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

const { mapState, mapActions } = createNamespacedHelpers('storeCart')

export default {
  name: 'Cart',
  data() {
    return {
      deliveryPrice: 20,
      discount: 0,
      baseUrl: '',
    }
  },
  computed: {
    ...mapState(['cartItems']),
    // subtotal() {
    //   let total = 0
    //   this.cartItems.forEach((item) => {
    //     let count = item.colors.sizes.reduce((sum, curr) => sum + curr.count, 0)
    //     total += count * item.newPrice
    //   })
    //   return total
    // },
    total() {
      return this.cartItems.reduce((acc, curr) => {
        if (+curr.size.price.discount.view) {
          return (acc +=
            (curr?.size?.price.view - curr?.size?.price.discount.view) *
            curr.size.count)
        } else {
          return (acc += curr?.size?.price.view * curr.size.count)
        }
      }, 0)
    },
    subtotal() {
      let totalDiscount
      let totalPrice
      let result
      totalPrice = this.cartItems.reduce((acc, curr) => {
        return (acc += curr?.size?.price.view * curr.size.count)
      }, 0)
      totalDiscount = this.cartItems.reduce((acc, curr) => {
        if (+curr.size.price.discount.view) {
          return (acc +=
            (curr?.size?.price.view - curr?.size?.price.discount.view) *
            curr.size.count)
        } else {
          return 0
        }
      }, 0)

      if (totalDiscount === 0) {
        result = 0
      } else {
        result = totalPrice - totalDiscount
      }

      return result
    },
  },
  methods: {
    ...mapActions([
      'removeCartItem',
      'increaseCartItemCount',
      'decreaseCartItemCount',
      'setSelectedProduct',
    ]),
    selectProduct(item) {
      const copy = cloneDeep(item.product)
      copy.colors = []
      copy.colors[0] = item.colors
      this.setSelectedProduct(copy)
      this.$router.push({ name: 'Product', params: { id: item.product.slug } })
    },
    incrementSizeCount(cartId) {
      this.increaseCartItemCount(cartId)
    },
    decrementSizeCount(cartId) {
      this.decreaseCartItemCount(cartId)
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style lang="scss" scoped>
.cart__table-item__desc {
  h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  span {
    color: #b2b2b2;
    font-size: 14px;
    line-height: initial;
  }
}
.cart__table-footer {
  border-top: 1px solid #f2f2f2;
}
</style>
